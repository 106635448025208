<template>
  <g>
    <svg:style>
      .cls-2,
      .cls-4 {
        stroke: #aeaeae;
        stroke-miterlimit: 10;
        stroke-width: 0.25px;
      }

      .cls-2 {
        fill: none !important;
      }

      .cls-4 {
        fill: url(#glass-pattern);
      }
    </svg:style>

    <linearGradient
      id="glass-gradient"
      :x1="doorLeftWidth1 + 33.93"
      :y1="doorTopHeight1 + 274.95"
      :x2="doorLeftWidth1 + 33.93"
      :y2="doorTopHeight1 + 21.9"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0" stop-color="#f4f4f5" />
      <stop offset="0.5" stop-color="#dde7e6" />
      <stop offset="1" stop-color="#d1cece" />
    </linearGradient>

    <g id="L15">
      <rect
        v-if="showInoxed"
        id="inox"
        class="cls-2"
        :x="doorLeftWidth1 + 46.41"
        :y="inoxY"
        width="11.6"
        :height="inoxH"/>
      <!-- line class="line"
            v-if="showImpost"
            :x1="`${doorLeftWidth + 59.58}`"
            :y1="`${doorTopHeight + (showBg ? (doorHeight - 2 * inoxOffset) : (doorHeight - 2 * leaf2Left)) + (showBg ? inoxOffset : leaf2Left)}`"
            :x2="`${doorLeftWidth + 59.58}`"
            :y2="`${doorTopHeight + (showBg ? inoxOffset : leaf2Left)}`"/ -->
      <!-- line class="line"
          v-if="showImpost"
          :x1="lineX"
          :y1="lineY1"
          :x2="lineX"
          :y2="lineY2"/-->
      <line x1="59.73" y1="278" x2="59.73" y2="22.26" class="line"></line>
      <rect
        id="glass"
        class="cls-4"
        :x="`${doorLeftWidth + 22.26}`"
        :y="`${doorTopHeight + 22.26}`"
        width="25.83"
        :height="inoxHeight"
      />
    </g>
  </g>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  props: [
    'showBg',
    'doorWidth',
    'doorHeight',
    'doorLeftWidth',
    'doorTopHeight',
    'doorGlassImage',
    'showInox'
  ],
  data() {
    return {
      inoxOffset: 22.26,
      leafOffset: 6.4
    };
  },
  computed: {
    ...mapGetters('config', ['options']),
    showInoxed() {
      const construction_type = this.options.construction_type;
      const id = this.$parent.id;
      if (construction_type === 'nakladna_both_sides') {
        return false;
      }
      if (construction_type === 'nakladna_one_side') {
        if (id === 'svgObject1') {
          return false;
        } else {
          return this.showInox;
        }
      }
      return this.showInox;
    },
    inoxHeight() {
      return this.doorHeight - 2 * this.inoxOffset;
    },
    inox1X() {
      return this.doorLeftWidth + this.doorWidth * 0.65;
    },
    inox2X() {
      return this.doorLeftWidth + this.doorWidth * 0.35;
    },
    inox1Y() {
      return this.doorTopHeight + this.doorHeight / 2 - 68.6;
    },
    doorLeftWidth1() {
      return this.doorLeftWidth + this.doorWidth / 2 - 70;
    },
    doorTopHeight1() {
      return this.doorTopHeight + this.doorHeight / 2 - 150;
    },

    lineX() {
      return this.showBg ?
        this.doorLeftWidth + this.lineOffset :
        this.doorLeftWidth + this.glassWidth + this.glassX;
    },
    lineY2() {
      return this.showBg ?
        this.doorTopHeight + this.doorLeafOffset :
        this.doorTopHeight + this.leaf2Left
    },
    lineY1() {
      return this.showBg ?
        this.doorTopHeight + this.doorHeight - this.doorLeafOffset :
        this.doorTopHeight + this.doorHeight - this.leaf2Left
    },

    inoxY() {
      return this.showBg
        ? this.doorTopHeight + this.inoxOffset
        : this.doorTopHeight + this.leafOffset;
    },
    inoxH() {
      return this.showBg
        ? this.doorHeight - this.inoxOffset * 2
        : this.doorHeight - this.leafOffset * 2;
    },
    glassHeight() {
      return this.showBg
        ? this.doorHeight - this.inoxOffset
        : this.doorHeight - this.leafOffset;
    }
  }
};
</script>
